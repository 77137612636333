import React from 'react';
import cn from 'classnames';
import { graphql, PageProps } from 'gatsby';

import { StaticImage } from '../views/Image';
import StaticPage from '../views/Layout/StaticPage';

import styles from './corporate-governance.module.scss';

export const query = graphql`
  query ($language: String!) {
    content: markdownRemark(frontmatter: { type: { eq: "corporate-governance" }, lang: { eq: $language } }) {
      frontmatter {
        title
        subtitle
      }
      html
    }
  }
`;

interface QueryData {
  content?: {
    frontmatter: {
      title: string;
      subtitle: string;
    };
    html: string;
  };
}

export default function CorporateGovernance({ data }: PageProps<QueryData>) {
  return (
    <StaticPage
      className="my-5"
      heroImg={<StaticImage alt="" className="hero__bg" name="hp_hero_grc" />}
      pageview="corporate_governance"
      title={data.content.frontmatter.title}
    >
      <div className={cn(styles.container)} dangerouslySetInnerHTML={{ __html: data.content.html }} />
    </StaticPage>
  );
}
